import React from "react";
import Navigation from '../components/navigation';
import Layout from "../components/layout";
import SEO from "../components/seo";
import Feed from "../components/feed";
import { graphql } from 'gatsby';
import Footer from '../components/footer';


const portfolio = (props) => {

  const featuredContent = props.data.allContentfulWork;
  
  return (
     <Layout>
      <SEO title="Portfolio" keywords={[`photographer`, `fotograf`, `dusseldorf`, `düsseldorf`, `germany`, `写真家`, `portrait`, `porträt`, `カメラマン`, `TFP`, `landscape`, `hobby`, `japanese`, `street`, `outdoor`]} />
      <Navigation works={true} />
      <Feed data={featuredContent} />
      <Footer />
    </Layout>
  );
};

export default portfolio;

export const query = graphql` 
query PortfolioQuery {
  allContentfulWork(
      sort: { fields: [createdAt], order: DESC }
      filter: {
      node_locale: {eq: "en-US"},
      isFeatured: {eq: true}
    }
    ) {
    edges {
      node {
        id
        title
        picture {
          fluid(maxWidth: 1200, quality: 95) {
            src
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
}
`
